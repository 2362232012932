/**
 * Shallow matches two arrays.
 *
 * @returns {boolean} True if all elements in array a match
 *                    in array b. False otherwise.
 */
const matchArrays = (a, b) => {
  return (
    a === b ||
    (Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]))
  )
}

/**
 * Matches all fields in object a to some fields in object b.
 * Only matches objects and will not match arrays. The objects are
 * constructed from documents (AST and JSON) so they will not have
 * circular references. Do not run this on objects that have circular
 * references.
 *
 * @returns {boolean} True if all fields in Object A match fields in Object B. False
 *          otherwise.
 */
const matchPartialObjects = (a, b) => {
  if (Array.isArray(a)) {
    throw new Error(
      `Array matching not supported ${JSON.stringify(a, null, 2)}`
    )
  }

  for (const key of Object.keys(a)) {
    const aValue = a[key]
    const bValue = b[key]
    if (
      aValue &&
      bValue &&
      typeof aValue === 'object' &&
      typeof bValue === 'object'
    ) {
      if (!matchPartialObjects(aValue, bValue)) {
        return false
      }
    } else if (aValue !== bValue) {
      return false
    }
  }

  return true
}

/**
 * Matches a response object against a set of rules and returns
 * the first rule that matches or null if none match.
 *
 * A rule is is an object in this format:
 *   {
 *     name: string, // the type fo rule (Right now just toastRetry)
 *     args: {
 *       retryOn: Array({
 *         kind: string, // The error type to match
 *         error: {} // Partial object match
 *       })
 *     },
 *     fieldKey: string // field name to match against
 *   }
 * @returns {Object?} the first matched rule or null if no matches are found
 */
export const matcher = ({ data, errors }, rules) => {
  if (!data && errors && errors.length && rules && rules.length) {
    for (const error of errors) {
      for (const rule of rules) {
        if (!matchArrays([rule.fieldKey], error.path)) return null
        if (rule.args.retryOn) {
          for (const { error: errorMatch, kind } of rule.args.retryOn) {
            const matchObj = errorMatch ? { ...errorMatch } : {}
            if (kind) {
              matchObj.__typename = kind
            }

            if (matchPartialObjects(matchObj, error)) {
              return rule
            }
          }
        }
      }
    }
  }

  return null
}

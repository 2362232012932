import trimEnd from 'lodash/trimEnd'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Resolvers
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'

import { envFromOrderHostname } from '@toasttab/do-secundo-env-from-orders-hostname'
import { authenticationResolvers } from '@toasttab/do-secundo-authentication'
import { getHttpLink } from './links/http-link'
import { getErrorLink } from './links/error-link'
import { getRetryLinkFactory } from './links/retry-link'
import PossibleTypesResult from '../apollo/generated/PossibleTypes'
import { getAuthorizationHeaderLink } from 'cornucopia-apis'
import { AuthClient } from '@toasttab/guest-authentication-js'

// const doNotCacheThisType = () => null

interface DataObject {
  __typename: string
  guid?: string | null
}
interface DataIdFromObjectMap {
  [key: string]: (object: DataObject) => string | null
}

const dataIdFromObjectMap: DataIdFromObjectMap = {
  _default: (object: DataObject) => object.guid || null
}

export const dataIdFromObject = (object: DataObject) => {
  const idMapper =
    dataIdFromObjectMap[object.__typename] || dataIdFromObjectMap._default
  return idMapper(object)
}

export const cache = new InMemoryCache({
  // dataIdFromObject follows the v2 apollo InMemoryCacheConfig type
  // thus there is an error so this is to avoid that
  //@ts-ignore
  dataIdFromObject,
  possibleTypes: PossibleTypesResult.possibleTypes
})

// looks like this was a private method that we were accessing in our
// js version of this but typescript is more strict so we're ignoring
// to bypass typescript errors
//@ts-ignore
cache.deleteKeyByRegex = (regex) => {
  //@ts-ignore
  Object.keys(cache.data.data).forEach((key) => {
    if (key.match(regex)) {
      //@ts-ignore
      cache.data.delete(key)
    }
  })
}

//@ts-ignore
const ooGlobals = window.OO_GLOBALS || {}
const featureFlags = ooGlobals.enabledFeatureFlags || []
const enableRetries = featureFlags.includes('oo-v3-allow-apollo-retry')
const env = envFromOrderHostname(window.location.hostname)
const prefix = env === 'prod' ? 'ws-api' : 'ws-preprod-api.eng'

export const resolvers = [authenticationResolvers]

const GATEWAY_BASE_URI = trimEnd(
  process.env.GATEWAY_BASE_URI ||
    ooGlobals.gatewayBaseUri ||
    `https://${prefix}.toasttab.com`,
  '/'
)
const BFF_BASE_URI = trimEnd(
  process.env.BFF_BASE_URI ||
    ooGlobals.bffBaseUri ||
    `${GATEWAY_BASE_URI}/do-federated-gateway/v1`,
  '/'
)
const OO_BASE_URI = trimEnd(
  process.env.OO_BASE_URI ||
    ooGlobals.ooBaseUri ||
    `${GATEWAY_BASE_URI}/online-ordering/v1`,
  '/'
)
const RESTAURANT_GUID = ooGlobals.restaurantGuid || ''

interface ApolloConfig {
  cache: InMemoryCache
  GATEWAY_BASE_URI: string
  BFF_BASE_URI: string
  OO_BASE_URI: string
  RESTAURANT_GUID: string
  authClient: AuthClient | null
  client: ApolloClient<any> | null
}
export const config: ApolloConfig = {
  client: null,
  authClient: null,
  cache,
  GATEWAY_BASE_URI,
  BFF_BASE_URI,
  OO_BASE_URI,
  RESTAURANT_GUID
}

type LinkFactory = (config: ApolloConfig) => ApolloLink | BatchHttpLink

const linkFactories: LinkFactory[] = [
  getRetryLinkFactory(enableRetries),
  getErrorLink,
  getAuthorizationHeaderLink,
  getHttpLink
]

const getApolloClientOptionsLink = (apolloLinks: LinkFactory[]): ApolloLink =>
  ApolloLink.from(apolloLinks.map((fn) => fn(config)))

const apolloClientOptions = {
  link: getApolloClientOptionsLink(linkFactories),
  cache: config.cache,
  resolvers,
  name: process.env.PKG_NAME,
  version: process.env.PKG_VERSION
}

interface AuthApolloClient {
  link: ApolloLink[]
  cache: InMemoryCache
  resolvers: Resolvers
  name: string
  version: string
}

/**
 * Returns apolloClient for use with PasswordlessAuthentication
 * @returns ApolloClient
 */
export const getApolloClient = (
  authClient: AuthClient
): ApolloClient<AuthApolloClient> => {
  if (authClient) {
    linkFactories.unshift(() => authClient.authApolloLink)
    apolloClientOptions.link = getApolloClientOptionsLink(linkFactories)
    // add authClient to config so that we can import via resolvers
    config.authClient = authClient
  }

  config.client = new ApolloClient(apolloClientOptions)

  return config.client
}

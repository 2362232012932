import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { BanquetProps } from 'banquet-runtime-modules'
import { dispatchAuthClient, isProfileCreated } from 'cornucopia-apis'
import {
  AuthProvider,
  lazyInitAuthClient
  //@ts-ignore
} from '@toasttab/do-secundo-authentication'
import { getApolloClient } from '../apollo/apollo-client'

interface AppProps extends BanquetProps {
  children?: React.ReactNode | null
}

export const App = (props?: AppProps) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [authClient] = React.useState(() => {
    const authClientResult = lazyInitAuthClient({
      // called on user login refresh
      whenUserSessionHasResumed: () => {
        // @ts-ignore
        return setIsAuthenticated(isProfileCreated(authClient))
      }
    })

    dispatchAuthClient(authClientResult)
    return authClientResult
  })
  const apolloClient = getApolloClient(authClient)

  return (
    <ApolloProvider client={apolloClient}>
      <AuthProvider
        apolloClient={apolloClient}
        authClient={authClient}
        // @ts-ignore
        setIsAuthenticated={setIsAuthenticated}
        isAuthenticated={isAuthenticated}
      >
        {props && props.children}
      </AuthProvider>
    </ApolloProvider>
  )
}

import { RetryLink } from './toast-link-retry'
import { matcher } from './match-rules'

/**
 * Only retry on certain errors
 */
const RETRY_ON_MESSAGES = [
  /*
   * Network issues on the client or downstream can cause this error
   * to fire:
   *   - Being temporarily offline
   *   - Url is temporarily down
   */
  'Failed to fetch'
]

/**
 * Put the OO retry buisness logic for standard Apollo errors here.
 */
const retryIf = (error, _operation) =>
  !!error && RETRY_ON_MESSAGES.indexOf(error.message) !== -1

/**
 * Put the OO retry buisness logic for custom errors here.
 */
export const retryIfCustomError = (value, _operation, matchRules) =>
  Boolean(matcher(value, matchRules))

export const getRetryLinkFactory = (retryEnabled) => {
  return () =>
    new RetryLink({
      retryEnabled,
      delay: {
        initial: 300,
        max: 2000,
        jitter: true
      },
      attempts: {
        max: 2,
        retryIf,
        retryIfCustomError
      }
    })
}

import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact, initSentry } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { getErrorBoundary, getLogger } from '@toasttab/do-secundo-sentry-client'
import { App } from './app/App'
import { getSentryConfig } from 'do-cornucopia-root'

import './index.css'

const tempWindow: any = window
const sentryConfig = getSentryConfig(tempWindow.OO_GLOBALS.mode)
const { hub, client } = initSentry({
  ...sentryConfig,
  releaseVersion: process.env.PKG_VERSION
})

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-corn-guest-auth-spa',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],
  initSentry: () => ({ hub, client }),
  //@ts-ignore: Hub type mismatch between do-secundo and banquet - TODO: remove when fixed
  sentryErrorBoundary: getErrorBoundary(hub, {
    spa: 'corn-guest-auth-spa',
    team: 'GIA'
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'corn-guest-auth-spa'
// @ts-ignore: Hub type mismatch between do-secundo and banquet - TODO: remove when fixed
export const logError = getLogger(hub, {
  spa: 'corn-guest-auth-spa',
  team: 'GIA'
})

/* eslint-disable */
/* Generated by GraphQL Code Generator against pre-production environment */


      export default {
  "possibleTypes": {
    "ApplyPromoCodeResponseOrError": [
      "ApplyPromoCodeResponse",
      "ApplyPromoCodeError"
    ],
    "ApplyPromoCodeResponseOrErrorV2": [
      "CartResponse",
      "CartModificationError",
      "CartOutOfStockError",
      "ApplyPromoCodeError"
    ],
    "CartModificationResponseOrError": [
      "CartResponse",
      "CartModificationError",
      "CartOutOfStockError"
    ],
    "CartResponseOrError": [
      "CartResponse",
      "CartError"
    ],
    "CompleteIdentityProfileResponseOrError": [
      "CompleteIdentityProfileResponse",
      "CompleteIdentityProfileError"
    ],
    "CompleteProfileCreationResponseOrError": [
      "CompleteProfileCreationResponse",
      "CompleteProfileCreationError"
    ],
    "CreateAccountResponseOrError": [
      "CreateAccountResponse",
      "CreateAccountError"
    ],
    "CreateConfirmedAccountResponseOrError": [
      "AuthenticationResponse",
      "CreateAccountError"
    ],
    "CustomerLoyaltyInfoResponseOrError": [
      "LoyaltyInquiryResponse",
      "CustomerLoyaltyError"
    ],
    "DeleteAccountResponseOrError": [
      "DeleteAccountResponse",
      "DeleteAccountError"
    ],
    "DeleteAddressResponseOrError": [
      "DeleteAddressResponse",
      "DeleteAddressError"
    ],
    "DeleteCreditCardResponseOrError": [
      "DeleteCreditCardResponse",
      "DeleteCreditCardError"
    ],
    "DoMenus_FindMenuItemResult": [
      "DoMenus_FindMenuItemError",
      "DoMenus_FindMenuItemResponse"
    ],
    "DoMenus_PopularItemsResult": [
      "DoMenus_PopularItemsError",
      "DoMenus_PopularItemsResponse"
    ],
    "Error": [
      "ApplyPromoCodeError",
      "CartError",
      "CartModificationError",
      "CartOutOfStockError",
      "CompleteIdentityProfileError",
      "CompleteProfileCreationError",
      "CreateAccountError",
      "CustomerLoyaltyError",
      "DeleteAccountError",
      "DeleteAddressError",
      "DeleteCreditCardError",
      "GeneralError",
      "GiftCardBalanceInquiryError",
      "GuestFeedbackV2Error",
      "ImportAccountError",
      "LoginError",
      "LoyaltySignupError",
      "MakeCreditCardPrimaryError",
      "OPTOrderError",
      "OPTPartyError",
      "OPTTabError",
      "OrderAtTheTableGiftCardBalanceInquiryError",
      "PasswordlessAuthenticationError",
      "PayForCheckError",
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PopularItemsError",
      "RefreshError",
      "ReorderError",
      "ResetPasswordError",
      "SignUpToLoyaltyError",
      "StartIdentityProfileError",
      "StartProfileCreationError",
      "UpdateAddressError",
      "UpdateBasicInfoError",
      "UpdatePasswordError",
      "ValidateApplePayMerchantError",
      "VerifyEmailError"
    ],
    "FutureFulfillmentTimeSlot": [
      "FutureFulfillmentTime",
      "FutureFulfillmentServiceGap"
    ],
    "GetCustomer3PLoyaltyAccountsResponseOrError": [
      "GetCustomer3PLoyaltyAccountsResponse",
      "CustomerLoyaltyError"
    ],
    "GetLoyalty3PRewardsFromCartResponseOrError": [
      "GetLoyalty3PRewardsFromCartResponse",
      "CustomerLoyaltyError"
    ],
    "GiftCardBalanceInquiryResponseOrError": [
      "GiftCardBalanceInquiryResponse",
      "GiftCardBalanceInquiryError"
    ],
    "ImportAccountResponseOrError": [
      "ImportAccountResponse",
      "ImportAccountError"
    ],
    "Info": [
      "CartInfo"
    ],
    "LoginResponse": [
      "AuthenticationResponse",
      "MfaChallengeGeneratedResponse",
      "LoginError"
    ],
    "LoyaltyInquiryResponseOrError": [
      "LoyaltyInquiryResponse",
      "CartOutOfStockError",
      "CustomerLoyaltyError"
    ],
    "LoyaltySignupResponseOrError": [
      "LoyaltySignupResponse",
      "LoyaltySignupError"
    ],
    "MakeCreditCardPrimaryResponseOrError": [
      "MakeCreditCardPrimaryResponse",
      "MakeCreditCardPrimaryError"
    ],
    "MenusResponseOrError": [
      "MenusResponse",
      "GeneralError"
    ],
    "OPTAddToTabResponseOrError": [
      "AddToOPTTabResponse",
      "OPTTabError"
    ],
    "OPTPartyCartResponse": [
      "OrderAtTableCart",
      "OPTPartyError"
    ],
    "OPTPartyRefreshResponse": [
      "OPTPartyRefresh",
      "OPTPartyError"
    ],
    "OPTPartyResponse": [
      "OPTParty",
      "OPTPartyError"
    ],
    "OPTPartyStubResponse": [
      "OPTPartyStub",
      "OPTPartyError"
    ],
    "OPTSplitPaymentChargesResponseOrError": [
      "OPTSplitPaymentChargesResponse",
      "OPTPartyError"
    ],
    "OPTTabHistoryResponse": [
      "OPTTabHistoryOrder",
      "OPTTabError"
    ],
    "OrderAtTheTableGiftCardBalanceInquiryResponseOrError": [
      "OrderAtTheTableGiftCardBalanceInquiryResponse",
      "OrderAtTheTableGiftCardBalanceInquiryError"
    ],
    "PasswordlessLoginResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessLoginResponse"
    ],
    "PasswordlessLoginUnifiedResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessLoginUnifiedResponse"
    ],
    "PasswordlessLogoutResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessLogoutResponse"
    ],
    "PasswordlessSignupResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessSignupResponse"
    ],
    "PasswordlessTokenResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessTokenResponse"
    ],
    "PasswordlessTokenUnifiedResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessTokenUnifiedResponse"
    ],
    "PayForCheckResponseOrError": [
      "PayForCheckResponse",
      "PayForCheckError"
    ],
    "PlaceApplePayOrderResponse": [
      "PlaceOrderResponse",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderCartUpdatedError"
    ],
    "PlaceCashOrderResponse": [
      "PlaceOrderResponse",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderCartUpdatedError"
    ],
    "PlaceCcOrderResponse": [
      "PlaceOrderResponse",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderCartUpdatedError"
    ],
    "PlaceOPTOrderResponse": [
      "OPTPayForCheckResponse",
      "OPTOrderError"
    ],
    "PopularItemsResponseOrError": [
      "PopularItemsResponse",
      "PopularItemsError"
    ],
    "PromoBannerBase": [
      "DeliveryBanner",
      "LoyaltyBanner",
      "PromoCodeBanner"
    ],
    "PromoBannerResponseOrError": [
      "PromoBannerResponse",
      "GeneralError"
    ],
    "RefreshResponse": [
      "AuthenticationResponse",
      "RefreshError"
    ],
    "RemovePromoCodeResponseOrError": [
      "CartResponse",
      "CartModificationError",
      "CartOutOfStockError"
    ],
    "ReorderResponseOrError": [
      "ReorderResponse",
      "ReorderError"
    ],
    "ResetPasswordResponse": [
      "AuthenticationResponse",
      "ResetPasswordError"
    ],
    "RestaurantOrError": [
      "Restaurant",
      "GeneralError"
    ],
    "RestaurantSearchResultsOrError": [
      "RestaurantSearchResults",
      "GeneralError"
    ],
    "SignUpToLoyaltyResponseOrError": [
      "SignUpToLoyaltyResponse",
      "SignUpToLoyaltyError"
    ],
    "StartProfileCreationResponseOrError": [
      "StartProfileCreationResponse",
      "StartProfileCreationError"
    ],
    "SubmitGuestFeedbackV2Response": [
      "GuestFeedbackV2SuccessResponse",
      "GuestFeedbackV2Error"
    ],
    "UpdateAddressResponseOrError": [
      "UpdateAddressResponse",
      "UpdateAddressError"
    ],
    "UpdateBasicInfoResponseOrError": [
      "UpdateBasicInfoResponse",
      "UpdateBasicInfoError"
    ],
    "UpdatePasswordResponseOrError": [
      "UpdatePasswordResponse",
      "UpdatePasswordError"
    ],
    "ValidateApplePayMerchantResponse": [
      "ValidateApplePayMerchantSuccessResponse",
      "ValidateApplePayMerchantError"
    ],
    "VerifyEmailResponse": [
      "AuthenticationResponse",
      "VerifyEmailError"
    ],
    "Warning": [
      "CartWarning",
      "DeliveryUnavailableReason",
      "PlaceOrderWarning",
      "ReorderWarning"
    ]
  }
}
    
// Generated on 2022-09-22T10:14:51-07:00

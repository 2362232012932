import { ApolloLink, Observable } from '@apollo/client'
import {
  checkDocument,
  hasDirectives,
  removeDirectivesFromDocument
} from '@apollo/client/utilities'

import { buildDelayFunction } from './delay-function'
import {
  buildCustomErrorRetryFunction,
  buildRetryFunction
} from './retry-function'
import { RETRY_DIRECTIVE } from './constants'
import { parseMatchRulesFromDocument } from './query-parser'

/** Removes just the client side directive. */
const REMOVE_DIRECTIVE_CONFIG = {
  name: RETRY_DIRECTIVE
}

/**
 * Tracking and management of operations that may be (or currently are) retried.
 */
class RetryableOperation {
  constructor(
    operation,
    nextLink,
    delayFor,
    retryIf,
    retryIfCustomError,
    retryMatchRules
  ) {
    this.operation = operation
    this.nextLink = nextLink
    this.delayFor = delayFor
    this.retryIf = retryIf
    this.retryIfCustomError = retryIfCustomError
    this.retryMatchRules = retryMatchRules

    this.retryCount = 0
    this.values = []
    this.error = undefined
    this.complete = false
    this.observers = []
    this.currentSubscription = null
    this.timerId = undefined

    this.onNext = (value) => {
      this.retryCount += 1

      const shouldRetry = this.retryIfCustomError(
        this.retryCount,
        this.operation,
        this.retryMatchRules,
        value
      )

      if (shouldRetry) {
        this.pendingRetry = true
        this.scheduleRetry(
          this.delayFor(this.retryCount, this.operation, value)
        )
        return
      }

      this.values.push(value)

      for (const observer of this.observers) {
        if (!observer) continue
        observer.next(value)
      }
    }

    this.onComplete = () => {
      // only complete if there is no pending retrys
      if (!this.timerId) {
        this.complete = true
        for (const observer of this.observers) {
          if (!observer) continue
          observer.complete()
        }
      }
    }

    this.onError = async (error) => {
      this.retryCount += 1

      // Should we retry?
      const shouldRetry = await this.retryIf(
        this.retryCount,
        this.operation,
        error
      )
      if (shouldRetry) {
        this.scheduleRetry(
          this.delayFor(this.retryCount, this.operation, error)
        )
        return
      }

      this.error = error
      for (const observer of this.observers) {
        if (!observer) continue
        observer.error(error)
      }
    }
  }

  /**
   * Register a new observer for this operation.
   *
   * If the operation has previously emitted other events, they will be
   * immediately triggered for the observer.
   */
  subscribe(observer) {
    if (this.canceled) {
      throw new Error(
        `Subscribing to a retryable link that was canceled is not supported`
      )
    }
    this.observers.push(observer)

    // If we've already begun, catch this observer up.
    for (const value of this.values) {
      observer.next(value)
    }

    if (this.complete) {
      observer.complete()
    } else if (this.error) {
      observer.error(this.error)
    }
  }

  /**
   * Remove a previously registered observer from this operation.
   *
   * If no observers remain, the operation will stop retrying, and unsubscribe
   * from its downstream link.
   */
  unsubscribe(observer) {
    const index = this.observers.indexOf(observer)
    if (index < 0) {
      throw new Error(
        `RetryLink BUG! Attempting to unsubscribe unknown observer!`
      )
    }
    // Note that we are careful not to change the order of length of the array,
    // as we are often mid-iteration when calling this method.
    this.observers[index] = null

    // If this is the last observer, we're done.
    if (this.observers.every((o) => o === null)) {
      this.cancel()
    }
  }

  /**
   * Start the initial request.
   */
  start() {
    if (this.currentSubscription) return // Already started.

    this.try()
  }

  /**
   * Stop retrying for the operation, and cancel any in-progress requests.
   */
  cancel() {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe()
    }
    clearTimeout(this.timerId)
    this.timerId = null
    this.currentSubscription = null
    this.canceled = true
  }

  try() {
    this.currentSubscription = this.nextLink(this.operation).subscribe({
      next: this.onNext,
      error: this.onError,
      complete: this.onComplete
    })
  }

  scheduleRetry(delay) {
    if (this.timerId) {
      throw new Error(`RetryLink BUG! Encountered overlapping retries`)
    }

    this.timerId = setTimeout(() => {
      this.timerId = null
      this.try()
    }, delay)
  }
}

export class RetryLink extends ApolloLink {
  constructor(options) {
    super()

    // Process document cache for fragments that are not tagged
    // for retry. Right now it is all or none for retry.
    this.nonTaggedDocuments = new Map()
    // Documents processed with their retry decorators remove
    // inplace. This set indicates if the document has been
    // previously processed.
    this.serverSanitizedDocuments = new Map()
    // Processed match rules.
    this.retryMatchRules = new Map()

    const { attempts, delay, retryEnabled } = options || {}

    this.delayFor =
      typeof delay === 'function' ? delay : buildDelayFunction(delay)
    this.retryIf =
      typeof attempts === 'function' ? attempts : buildRetryFunction(attempts)

    this.retryIfCustomError = buildCustomErrorRetryFunction(attempts)

    this.retryEnabled = retryEnabled
  }

  /**
   * Retry is client only so dont send the decorator
   * to the server. We must remove the decorator but
   * keep the query intact.
   */
  removeRetryDirectivesFromDocument(query) {
    const cached = this.serverSanitizedDocuments.get(query)
    if (cached) return cached

    checkDocument(query)

    const docClone = removeDirectivesFromDocument(
      [REMOVE_DIRECTIVE_CONFIG],
      query
    )

    this.serverSanitizedDocuments.set(query, docClone)
    return docClone
  }

  getMatchRulesFromDocument(query) {
    const cached = this.retryMatchRules.get(query)
    if (cached) return cached

    const matchRules = parseMatchRulesFromDocument(query)

    this.retryMatchRules.set(query, matchRules)
    return matchRules
  }

  request(operation, nextLink) {
    const { query } = operation

    const isRetryQuery = hasDirectives([RETRY_DIRECTIVE], query)
    if (!isRetryQuery) {
      return nextLink(operation)
    }

    if (!this.retryEnabled) {
      const sanitizedDoc = this.removeRetryDirectivesFromDocument(query)
      operation.query = sanitizedDoc
      return nextLink(operation)
    }

    const retryMatchRules = this.getMatchRulesFromDocument(query)
    const sanitizedDoc = this.removeRetryDirectivesFromDocument(query)

    operation.query = sanitizedDoc
    const retryable = new RetryableOperation(
      operation,
      nextLink,
      this.delayFor,
      this.retryIf,
      this.retryIfCustomError,
      retryMatchRules
    )
    retryable.start()

    return new Observable((observer) => {
      retryable.subscribe(observer)
      return () => {
        retryable.unsubscribe(observer)
      }
    })
  }
}

export function buildRetryFunction(retryOptions) {
  const { retryIf, max = 5 } = retryOptions || {}
  return function retryFunction(count, operation, error) {
    if (count >= max) return false
    return retryIf ? retryIf(error, operation) : !!error
  }
}

export function buildCustomErrorRetryFunction(retryOptions) {
  const { retryIfCustomError, max = 5 } = retryOptions || {}
  return function retryFunction(count, operation, matchRules, value) {
    if (count >= max) return false
    return retryIfCustomError
      ? retryIfCustomError(value, operation, matchRules)
      : false
  }
}

import { visit } from 'graphql/language/visitor'
import { RETRY_DIRECTIVE } from './constants'

const parseArgumentValue = (value) => {
  switch (value.kind) {
    case 'ListValue':
      return value.values.map((item) => {
        return parseArgumentValue(item)
      })
    case 'ObjectValue':
      return value.fields.reduce((acc, field) => {
        acc[field.name.value] = parseArgumentValue(field.value)
        return acc
      }, {})
    case 'StringValue':
      return value.value
    case 'IntValue':
      return Number(value.value)
    default:
      throw new Error(
        `Argument kind ${value.kind} is not supported: ${JSON.stringify(
          value,
          null,
          2
        )}`
      )
  }
}

const parseArguments = (args) => {
  return args.reduce((acc, arg) => {
    acc[arg.name.value] = parseArgumentValue(arg.value)
    return acc
  }, {})
}

const parseDirectiveNode = (node) => {
  if (node.name.value === RETRY_DIRECTIVE) {
    const directive = {
      name: node.name.value,
      args: parseArguments(node.arguments)
    }

    return directive
  }
}

export const parseMatchRulesFromDocument = (root) => {
  const matchRules = []

  visit(root, {
    Directive(node, _key, _parent, _path, ancestors) {
      const directive = parseDirectiveNode(node)
      if (directive) {
        const op = ancestors[ancestors.length - 1]
        const selectionSet = op && op.selectionSet

        if (selectionSet) {
          // Apply rules to all top level fields
          selectionSet.selections.forEach((field) => {
            const fieldDirective = { ...directive }
            fieldDirective.fieldKey = field.name.value
            matchRules.push(fieldDirective)
          })
        }
      }
    }
  })

  return matchRules
}
